import { MediaSlide } from "yet-another-react-lightbox";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import Media from "~/components/atoms/Media";
import { IMediaImageUrls, IMediaVideoUrls } from "~/entities/media";
import { EMedia } from "~/utilities/enums/Media";

export interface MediaThumbnailComponentProps {
  slide: MediaSlide;
}

const MediaThumbnailComponent: React.FC<MediaThumbnailComponentProps> = ({
  slide,
}) => {
  return slide.mediaType === EMedia.IMAGE ? (
    <ImageResponsive
      imageData={slide.media as IMediaImageUrls}
      className="h-24 w-28 object-contain"
    />
  ) : (
    <div className="relative h-24 w-28">
      <div className="absolute inset-0 z-10 bg-transparent" />
      <Media
        media={slide.media as IMediaVideoUrls}
        className="aspect-h-9 aspect-w-16 mx-auto h-full w-full object-cover"
      />
    </div>
  );
};

export default MediaThumbnailComponent;
