import ImageResponsive from "../ImageResponsive";
import Video from "../Video";

import { TMedia } from "~/entities/media";
import { EMedia } from "~/utilities/enums/Media";

export interface MediaProps {
  media: TMedia;
  className?: string;
  iframeClass?: string;
  autoPlay?: boolean;
  onPlay?: () => void;
}

const Media: React.FC<MediaProps> = ({
  media,
  className,
  iframeClass,
  autoPlay = false,
  onPlay,
}) => {
  return (
    <>
      {media.type === EMedia.IMAGE ? (
        <ImageResponsive className={className} imageData={media} />
      ) : (
        <Video
          videoData={media}
          autoPlay={autoPlay}
          className={className}
          iframeClass={iframeClass}
          videoProps={{ onPlay: onPlay }}
        />
      )}
    </>
  );
};

export default Media;
