import { useFetcher } from "@remix-run/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateValueType } from "react-tailwindcss-datepicker";
import { RemixFormProvider, useRemixForm } from "remix-hook-form";

import { SingleSelect } from "~/components/atoms/SelectBox";
import Typo from "~/components/atoms/Typo";
import ProjectSaleTransaction from "~/components/organisms/ProjectSaleTransaction";
import Section from "~/components/templates/SectionProject";
import {
  ISaleTransactionFilterOption,
  IUnitTransaction,
} from "~/entities/unitTransaction";
import { TProjectLoaderData } from "~/routes/($lang).projects.$slug/route";
import { IPaginateResponseData } from "~/types";
import { cn } from "~/utilities/cn";
import { LIMIT, PAGE } from "~/utilities/constants/common";
import { EApiParam } from "~/utilities/enums/ApiParam";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SectionProjectSaleTransactionProps {
  className?: string;
  id: string;
  title: string;
  description: string;
  unitTransactions: IPaginateResponseData<IUnitTransaction>;
  saleTransactionFilterOptions: ISaleTransactionFilterOption[];
}

const SectionProjectSaleTransaction: React.FC<
  SectionProjectSaleTransactionProps
> = ({
  className = "",
  id,
  title,
  description,
  unitTransactions,
  saleTransactionFilterOptions,
}) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<TProjectLoaderData>();
  const form = useRemixForm({ mode: "onChange" });

  const urlParams =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams();
  const fromDateParam = urlParams.get(EApiParam.START_DATE);
  const toDateParam = urlParams.get(EApiParam.END_DATE);

  const initialFromDate = fromDateParam ?? null;
  const initialToDate = toDateParam ?? dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]");

  const [dateRange, setDateRange] = useState<DateValueType>({
    startDate: initialFromDate,
    endDate: initialToDate,
  });

  const [
    selectedSaleTransactionFilterOption,
    setSelectedSaleTransactionFilterOption,
  ] = useState<ISaleTransactionFilterOption>();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (dateRange?.startDate) {
      searchParams.set(EApiParam.START_DATE, dateRange.startDate.toString());
    }
    if (dateRange?.endDate) {
      searchParams.set(EApiParam.END_DATE, dateRange.endDate.toString());
    }
    searchParams.set(EApiParam.PAGE, `${PAGE}`);
    searchParams.set(EApiParam.LIMIT, `${LIMIT}`);
    fetcher.load(`?${searchParams.toString()}`);
  }, [dateRange?.startDate, dateRange?.endDate]);

  const onLoadMore = () => {
    if (
      fetcher.state === "loading" ||
      (typeof fetcher?.data?.unitTransactions?.currentPage === "number" &&
        typeof fetcher?.data?.unitTransactions?.totalPage === "number" &&
        fetcher?.data?.unitTransactions?.currentPage ===
          fetcher?.data?.unitTransactions?.totalPage) ||
      (typeof unitTransactions?.currentPage === "number" &&
        typeof unitTransactions?.totalPage === "number" &&
        unitTransactions?.currentPage === unitTransactions?.totalPage)
    )
      return;

    const page = fetcher?.data?.unitTransactions?.currentPage
      ? fetcher?.data?.unitTransactions?.currentPage + 1
      : unitTransactions?.currentPage + 1;
    const limit = fetcher?.data?.unitTransactions?.limit
      ? fetcher?.data?.unitTransactions?.limit
      : unitTransactions?.limit;

    const searchParams = new URLSearchParams(window.location.search);
    if (dateRange?.startDate) {
      searchParams.set(EApiParam.START_DATE, dateRange.startDate.toString());
    }
    if (dateRange?.endDate) {
      searchParams.set(EApiParam.END_DATE, dateRange.endDate.toString());
    }
    searchParams.set(EApiParam.PAGE, `${page}`);
    searchParams.set(EApiParam.LIMIT, `${limit}`);
    fetcher.load(`?${searchParams.toString()}`);
  };

  const saleTransactionOptions = saleTransactionFilterOptions?.map(
    (option) => ({
      id: `${option?.fromDate}_${option?.toDate}`,
      name: `${option?.label} (${option?.count})`,
    })
  );
  const handleOnChange = (value: string) => {
    const selectedUnitTransactionFilterOption =
      saleTransactionFilterOptions?.find(
        (saleTransactionFilterOption) =>
          `${saleTransactionFilterOption?.fromDate}_${saleTransactionFilterOption?.toDate}` ===
          value
      );
    setSelectedSaleTransactionFilterOption(selectedUnitTransactionFilterOption);
    setDateRange({
      startDate: selectedUnitTransactionFilterOption?.fromDate || null,
      endDate: selectedUnitTransactionFilterOption?.toDate || null,
    });
  };

  return (
    <Section id={id} className={className}>
      <div
        className={cn(
          "flex flex-col gap-y-6 rounded-xl py-6 lg:gap-y-5 lg:px-6 lg:py-4.5",
          className
        )}
      >
        <div className="flex items-center justify-between px-0 lg:flex-col lg:items-start lg:gap-y-5">
          <div className="flex-1 space-y-2">
            <Typo
              className="font-bold"
              variant={ETypoVariant.HEADER_PROJECT_24}
              tag={ETypoTag.H3}
              color={ETypoColor.PROJECT_HEADING}
            >
              {t(title)}
            </Typo>
            {description && (
              <Typo variant={ETypoVariant.SUB_TITLE_16} tag={ETypoTag.P}>
                {t(description)?.replace(
                  "{number}",
                  `${selectedSaleTransactionFilterOption?.count || 0}`
                )}
              </Typo>
            )}
          </div>
          <RemixFormProvider {...form}>
            <SingleSelect
              placeholder=""
              defaultValue={saleTransactionOptions?.[0]}
              data={saleTransactionOptions}
              name="dateRange"
              title=""
              containerClass="w-full flex-1"
              hasAnyOption={false}
              dropdownClass="w-[calc(var(--button-width)+85px)]"
              onChange={handleOnChange}
            />
          </RemixFormProvider>
        </div>
        <ProjectSaleTransaction
          title={t(title)}
          description={t(description)}
          saleTransactions={fetcher?.data?.unitTransactions?.items || []}
          isLoading={fetcher.state === "loading"}
          onLoadMore={onLoadMore}
          page={fetcher?.data?.unitTransactions?.currentPage || 1}
        />
      </div>
    </Section>
  );
};

export default SectionProjectSaleTransaction;
