import isEmpty from "lodash/isEmpty";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { IMediaVideoUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";

export interface VideoYouTubeProps {
  className?: string;
  iframeClass?: string;
  videoData: IMediaVideoUrls;
}

const VideoYouTube: React.FC<VideoYouTubeProps> = ({
  className,
  iframeClass,
  videoData,
}) => {
  const { t } = useTranslation();

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  const extractVideoId = (url: string) => {
    if (!isValidUrl(url)) {
      return null;
    }

    const urlObj = new URL(url);
    if (urlObj.hostname === "youtu.be") {
      return urlObj.pathname.slice(1);
    } else if (urlObj.hostname.includes("youtube.com")) {
      return urlObj.searchParams.get("v");
    }
    return null;
  };

  const videoId =
    (!isEmpty(videoData?.urls) && extractVideoId(videoData?.urls[0])) || "";

  if (!videoId) {
    return <div className="text-red-500">{t("youtube.invalid")}</div>;
  }

  const iframeRef = useRef<any>(null);

  useEffect(() => {
    const onPlayerStateChange = (event: any) => {
      if (event.data === (window as any).YT.PlayerState.PLAYING) {
        const iframe = iframeRef.current;
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen();
        } else if (iframe.mozRequestFullScreen) {
          // Firefox
          iframe.mozRequestFullScreen();
        } else if (iframe.webkitRequestFullscreen) {
          // Chrome, Safari, and Opera
          iframe.webkitRequestFullscreen();
        } else if (iframe.msRequestFullscreen) {
          // IE/Edge
          iframe.msRequestFullscreen();
        }
      }
    };

    const onPlayerReady = (event: any) => {
      // Any additional setup on player ready can be handled here
    };

    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);

    (window as any).onYouTubeIframeAPIReady = function () {
      // @ts-ignore
      new window.YT.Player(iframeRef.current, {
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    };
  }, []);

  return (
    <div className={cn(className)}>
      <iframe
        id="existing-iframe-example"
        ref={iframeRef}
        className={cn("h-full w-full", iframeClass)}
        src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
        title={t("youtube.title")}
        style={{ border: "0" }}
        allow="accelerometer; autoplay; fullscreen;clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoYouTube;
