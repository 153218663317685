import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";

import { HorizontalTable } from "~/components/atoms/Table";
import { Column } from "~/components/atoms/Table/types";
import Typo from "~/components/atoms/Typo";
import { IUnitTransaction } from "~/entities/unitTransaction";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

dayjs.extend(utc);

export interface ProjectSaleTransactionProps {
  className?: string;
  saleTransactions: IUnitTransaction[];
  title: string;
  description?: string;
  page?: number;
  isLoading?: boolean;
  onLoadMore?: () => void;
}

const ProjectSaleTransaction: React.FC<ProjectSaleTransactionProps> = ({
  className = "",
  saleTransactions,
  page,
  isLoading,
  onLoadMore,
}) => {
  const { t } = useTranslation();

  const tableTranslator =
    "project.sections.sales_transaction.table.header_name";

  const COLUMNS: Column[] = [
    {
      headerName: t(`${tableTranslator}.date`),
      field: "date",
      renderCell: (row) => {
        const date = dayjs.utc(row?.timestamp).format("DD MMM YYYY");
        const time = dayjs.utc(row?.timestamp).format("HH:mm");
        return (
          <div className="space-x-6">
            <Typo
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.TABLE_TEXT}
              tag={ETypoTag.SPAN}
            >
              {time}
            </Typo>
            <Typo
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.TABLE_TEXT}
              tag={ETypoTag.SPAN}
            >
              {date}
            </Typo>
          </div>
        );
      },
    },
    {
      headerName: t(`${tableTranslator}.unit_type`),
      field: "unitType",
      headerCellClassName: "text-center",
      cellClassName: "text-center",
      renderCell: (row) => (
        <Typo
          className="font-normal"
          tag={ETypoTag.SPAN}
          variant={ETypoVariant.SUB_TITLE_16}
          color={ETypoColor.TABLE_TEXT}
        >
          {row?.unitType?.title}
        </Typo>
      ),
    },
    {
      headerName: t(`${tableTranslator}.unit`),
      field: "units",
      headerCellClassName: "text-center",
      cellClassName: "text-center",
      renderCell: (row) => (
        <Typo
          className="font-normal"
          tag={ETypoTag.SPAN}
          variant={ETypoVariant.SUB_TITLE_16}
          color={ETypoColor.TABLE_TEXT}
        >
          {row?.unitName}
        </Typo>
      ),
    },
  ];

  return (
    <div className={cn("lg:-mx-6", className)}>
      <HorizontalTable
        classNames={{
          wrapper: "max-h-[480px] scroll lg:no-scrollbar lg:px-6",
        }}
        columns={COLUMNS}
        rows={saleTransactions}
        stickyRows
        page={page}
        isLoading={isLoading}
        onLoadMore={onLoadMore}
        infinitiLoad
        variant="project"
      />
    </div>
  );
};

export default ProjectSaleTransaction;
