import isEmpty from "lodash/isEmpty";
import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

import VideoYouTube from "~/components/atoms/VideoYouTube";
import { IMediaVideoUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";

export interface VideoProps {
  videoData: IMediaVideoUrls;
  className?: string;
  iframeClass?: string;
  autoPlay?: boolean;
  controls?: boolean;
  muted?: boolean;
  captions?: string; // URL for the captions track
  poster?: string; // URL for the video thumbnail
  videoProps?: ComponentProps<"video">;
}

function isYouTubeUrl(url: string): boolean {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  return youtubeRegex.test(url);
}

const Video: React.FC<VideoProps> = ({
  videoData,
  className,
  iframeClass,
  autoPlay = true,
  controls = true,
  muted = false,
  captions,
  poster, // added poster prop
  videoProps,
}) => {
  const { t } = useTranslation();

  if (isYouTubeUrl(videoData?.urls?.[0] || "")) {
    return (
      <VideoYouTube
        videoData={videoData}
        className={className}
        iframeClass={iframeClass}
      />
    );
  }

  return (
    <div className={cn("aspect-h-9 aspect-w-16", className)}>
      <video
        autoPlay={autoPlay}
        controls={controls}
        muted={muted}
        poster={poster} // use the poster prop
        className={cn("h-full w-full rounded object-cover", className)}
        {...(videoProps as ComponentProps<"video">)}
      >
        {!isEmpty(videoData?.urls) &&
          videoData?.urls?.map((url: string, index: number) => (
            <source
              key={index}
              src={ENV.IMAGE_DOMAIN_URL + url}
              type="video/mp4"
            />
          ))}
        <track
          kind="captions"
          src={captions || "data:,"} // Fallback to an empty data URL if no captions provided
          srcLang="en"
          label="English"
          default
        />
        {t("invalid_messages.render_video")}
      </video>
    </div>
  );
};

export default Video;
