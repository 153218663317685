import React, { useEffect, useState } from "react";

import { cn } from "~/utilities/cn";
import { removeNbspParagraphs } from "~/utilities/helpers/removeNbspParagraphs";

interface Props {
  htmlContent: string;
  className?: string;
}

const CleanedContent: React.FC<Props> = ({ htmlContent, className }) => {
  const [cleanedContent, setCleanedContent] = useState("");

  useEffect(() => {
    // Clean the content only on the client side after the component has mounted
    const cleaned = removeNbspParagraphs(htmlContent);
    setCleanedContent(cleaned);
  }, [htmlContent]);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: cleanedContent }}
      className={cn("ql-editor", className)}
    />
  );
};

export default CleanedContent;
