import { useEffect, useRef, useState } from "react";

interface UseMenuScrollProps {
  sections: HTMLElement[];
}

const useMenuScroll = ({ sections }: UseMenuScrollProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const scrollToSection = (index: number) => {
    const section = sections[index];
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setActiveIndex(index);
    }
  };

  const ensureMenuItemVisible = (index: number) => {
    const menu = menuRef.current;
    const menuItem = menu?.children[index];

    if (menuItem) {
      const menuRect = menu.getBoundingClientRect();
      const itemRect = menuItem.getBoundingClientRect();

      if (itemRect.top < menuRect.top || itemRect.bottom > menuRect.bottom) {
        menuItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = sections?.map((section) => section?.offsetTop);
      const currentScroll = window.scrollY;

      const newIndex = sectionOffsets?.findIndex(
        (offset, index) =>
          currentScroll >= offset &&
          currentScroll < (sectionOffsets?.[index + 1] || Infinity)
      );

      if (newIndex !== -1 && newIndex !== activeIndex) {
        setActiveIndex(newIndex);
        ensureMenuItemVisible(newIndex);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeIndex, sections]);

  return { activeIndex, scrollToSection, menuRef };
};

export default useMenuScroll;
