import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ProjectUnitMix from "~/components/organisms/ProjectUnitMix";
import Section from "~/components/templates/SectionProject";
import { IProject } from "~/entities/project";
import { TUnitType } from "~/entities/unitType";
import { cn } from "~/utilities/cn";

export interface SectionProjectUnitMixProps {
  className?: string;
  id: string;
  title: string;
  description: string;
  project: IProject;
}

const defaultUnitTypeValues: Partial<TUnitType> = {
  availableUnits: 0,
  minPSF: 0,
  maxPSF: 0,
  maxPrice: 0,
  minPrice: 0,
  bedRoomCount: 0,
  hasStudyRoom: false,
  isPremium: false,
  isPrivateLift: false,
  isCompact: false,
  isCompactPlus: false,
  isDuplex: false,
  isPenthouse: false,
  isUtility: false,
  hasGuest: false,
  active: false,
  isStudio: false,
};

const SectionProjectUnitMix: React.FC<SectionProjectUnitMixProps> = ({
  className = "",
  id,
  title,
  description,
  project,
}) => {
  const { t } = useTranslation();

  // Memoize overall row calculation
  const overallRow = useMemo((): TUnitType | null => {
    if (!project || project.unitTypes.length === 0) return null;

    let smallestSize = project.unitTypes[0].minArea;
    let largestSize = project.unitTypes[0].maxArea;

    const overallTotalUnits = project.unitTypes.reduce((total, unit) => {
      smallestSize = Math.min(smallestSize, unit.minArea);
      largestSize = Math.max(largestSize, unit.maxArea);
      return total + unit.totalUnits;
    }, 0);

    return {
      id: "overall",
      slug: "overall",
      title: t("overall"),
      minArea: smallestSize,
      maxArea: largestSize,
      totalUnits: overallTotalUnits,
      ...defaultUnitTypeValues,
    } as TUnitType;
  }, [project, t]);

  const totalUnits = useMemo(() => {
    const total = project?.manualTotalUnitsCount || overallRow?.totalUnits;
    return total;
  }, [overallRow?.totalUnits, project?.manualTotalUnitsCount]);

  if (!project) return <div>{t("No project data available")}</div>;

  return (
    <Section id={id} className={cn("overflow-x-hidden", className)}>
      <ProjectUnitMix
        title={t(title)}
        description={t(description, {
          total: totalUnits ?? 0,
        })}
        unitMixData={
          overallRow ? [...project.unitTypes, overallRow] : project.unitTypes
        }
        totalUnits={project.totalUnits}
        overallTotalUnits={overallRow?.totalUnits ?? 0}
      />
    </Section>
  );
};

export default SectionProjectUnitMix;
