import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useRef, useState } from "react";
import Lightbox, {
  MediaSlide,
  ViewCallbackProps,
  ZoomRef,
} from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";

import MediaSlideComponent from "./components/MediaSlideComponent";
import MediaThumbnailComponent from "./components/MediaThumbnailComponent";

import { TMedia } from "~/entities/media";
import { EMedia } from "~/utilities/enums/Media";

export interface ModalMediaGalleryProps {
  medias: TMedia[];
  className?: string;
  indexSlide?: number;
  isOpen?: boolean;
  closeModal?: () => void;
}

const ModalMediaGallery: React.FC<ModalMediaGalleryProps> = ({
  medias,
  className = "",
  indexSlide = 0,
  isOpen = false,
  closeModal,
}) => {
  const zoomRef = useRef<ZoomRef>(null);
  const [zoomLevel, setZoomLevel] = useState(1); // Default zoom level to 1
  const [isClient, setIsClient] = useState(false); // Track if the component has mounted
  const [currentIndex, setCurrentIndex] = useState(indexSlide);

  useEffect(() => {
    setIsClient(true); // Set to true once the component has mounted
  }, []);

  const slides = useMemo<MediaSlide[]>(() => {
    if (isEmpty(medias)) return [];
    return medias
      .filter((media) => media && media.type) // Filter out any undefined or invalid media items
      .map((media, index) => ({
        type: "media-slide" as const,
        index,
        mediaType: media.type === EMedia.IMAGE ? EMedia.IMAGE : EMedia.VIDEO,
        media,
      }));
  }, [medias]);

  useEffect(() => {
    if (zoomRef.current) {
      zoomRef.current.disabled = false;
    }

    if (!isOpen) {
      setZoomLevel(1); // Reset zoom to default when modal is closed
    }
  }, [isOpen]);

  const handleViewChange = ({ index }: ViewCallbackProps) => {
    if (zoomLevel === 1) {
      setCurrentIndex(index);
    } else {
      setZoomLevel(1);
    }
  };

  // Only render the Lightbox once the component has mounted to avoid SSR mismatches
  if (!isClient) {
    return null;
  }

  return (
    <Lightbox
      open={isOpen}
      close={closeModal}
      plugins={[Thumbnails, Video, Zoom, Counter, Fullscreen]}
      slides={slides}
      zoom={{
        maxZoomPixelRatio: 3,
        scrollToZoom: true,
        zoomInMultiplier: 3,
        ref: zoomRef,
      }}
      render={{
        slide: ({ slide }) =>
          slide.type === "media-slide" ? (
            <MediaSlideComponent slide={slide} zoomLevel={zoomLevel} />
          ) : null,
        thumbnail: ({ slide }) => (
          <MediaThumbnailComponent slide={slide as MediaSlide} />
        ),
        iconZoomIn: () => undefined,
        iconZoomOut: () => undefined,
      }}
      toolbar={{
        buttons: [
          "zoom",
          <button
            key="zoom-in"
            type="button"
            className="zoom-icon yarl__button"
            onClick={() => setZoomLevel((prev) => Math.min(prev + 1, 3))}
            aria-label="Zoom In"
          >
            🔍+
          </button>,
          <button
            key="zoom-out"
            type="button"
            className="zoom-icon yarl__button"
            onClick={() => setZoomLevel((prev) => Math.max(prev - 1, 1))}
            aria-label="Zoom Out"
          >
            🔍-
          </button>,
          "close",
        ],
      }}
      index={currentIndex}
      video={{ playsInline: true, autoPlay: true, loop: true }}
      className={className}
      on={{
        view: handleViewChange,
      }}
    />
  );
};

export default ModalMediaGallery;
