import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MediaSlide, useLightboxState } from "yet-another-react-lightbox";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import Media from "~/components/atoms/Media";
import { IMediaImageUrls, IMediaVideoUrls, TMedia } from "~/entities/media";
import { cn } from "~/utilities/cn";
import { EMedia } from "~/utilities/enums/Media";

declare module "yet-another-react-lightbox" {
  export interface MediaSlide extends GenericSlide {
    type: "media-slide";
    mediaType: EMedia.IMAGE | EMedia.VIDEO;
    index: number;
    media: TMedia;
  }

  interface SlideTypes {
    "media-slide": MediaSlide;
  }
}

export interface MediaSlideComponentProps {
  slide: MediaSlide;
  zoomLevel: number;
}

const THRESHOLD_MOVE = 0.5;
const MediaSlideComponent: React.FC<MediaSlideComponentProps> = ({
  slide,
  zoomLevel,
}) => {
  const { currentIndex } = useLightboxState();
  const [dragState, setDragState] = useState({
    isDragging: false,
    dragPosition: { x: 0, y: 0 },
    startDrag: { x: 0, y: 0 },
  });
  const [isMounted, setIsMounted] = useState(false); // Track if the component has mounted
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setIsMounted(true); // Set to true once the component has mounted
  }, []);

  const isZooming = zoomLevel > 1;

  const handleStart = useCallback((e: MouseEvent | TouchEvent) => {
    e.preventDefault();

    const startX = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;
    const startY = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;

    setDragState((prevState) => ({
      ...prevState,
      isDragging: true,
      startDrag: {
        x: startX - prevState.dragPosition.x,
        y: startY - prevState.dragPosition.y,
      },
    }));
  }, []);

  const handleMove = useCallback(
    (e: MouseEvent | TouchEvent) => {
      e.preventDefault();

      if (!dragState.isDragging || zoomLevel <= 1) return;

      const moveX = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;
      const moveY = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;

      setDragState((prevState) => ({
        ...prevState,
        dragPosition: {
          x: moveX - prevState.startDrag.x,
          y: moveY - prevState.startDrag.y,
        },
      }));
    },
    [dragState.isDragging, zoomLevel]
  );

  const handleEnd = useCallback(() => {
    setDragState((prevState) => ({
      ...prevState,
      isDragging: false,
    }));
  }, []);

  useEffect(() => {
    setDragState({
      isDragging: false,
      dragPosition: { x: 0, y: 0 },
      startDrag: { x: 0, y: 0 },
    });
  }, [zoomLevel]);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    container.addEventListener("mousedown", handleStart);
    container.addEventListener("mousemove", handleMove);
    container.addEventListener("mouseup", handleEnd);
    container.addEventListener("mouseleave", handleEnd);
    container.addEventListener("touchstart", handleStart, { passive: false });
    container.addEventListener("touchmove", handleMove, { passive: false });
    container.addEventListener("touchend", handleEnd);

    return () => {
      container.removeEventListener("mousedown", handleStart);
      container.removeEventListener("mousemove", handleMove);
      container.removeEventListener("mouseup", handleEnd);
      container.removeEventListener("mouseleave", handleEnd);
      container.removeEventListener("touchstart", handleStart);
      container.removeEventListener("touchmove", handleMove);
      container.removeEventListener("touchend", handleEnd);
    };
  }, [handleStart, handleMove, handleEnd]);

  const transformStyle = useMemo(
    () => ({
      transform: `scale(${zoomLevel}) translate(${
        dragState.dragPosition.x * THRESHOLD_MOVE
      }px, ${dragState.dragPosition.y * THRESHOLD_MOVE}px)`,
    }),
    [zoomLevel, dragState.dragPosition]
  );

  // Avoid rendering the content on the server-side
  if (!isMounted || !slide.media || slide.index !== currentIndex) return null;

  return (
    <>
      {slide.mediaType === EMedia.IMAGE ? (
        <div className="h-full w-auto" ref={containerRef}>
          <ImageResponsive
            style={transformStyle}
            imageData={slide.media as IMediaImageUrls}
            className={cn(
              `relative h-full w-auto object-contain transition-transform duration-300`,
              isZooming &&
                (dragState.isDragging ? "cursor-grabbing" : "cursor-grab")
            )}
            draggable="false" // Disabled drag image
            displayDisclaimer={true}
            pictureClassName="h-fit"
          />
        </div>
      ) : (
        <Media
          media={slide.media as IMediaVideoUrls}
          className="mx-auto aspect-[16/9] h-auto w-full object-contain transition-transform duration-300"
        />
      )}
    </>
  );
};

export default MediaSlideComponent;
